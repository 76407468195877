<template>
	<div class="container">
		<div class="user-nav">
			<Search :isClass="false" :isSearch="false" :isBtn="false" :termlist="termlist" ref="search"
				@handleChange="handleChange" />
		</div>
		<div class="main">
			<div class="table-box">
				<h4>艺术素养</h4>
				<table border="1" cellspacing="0" rules="all">
					<tr>
						<th>项目</th>
						<th>写实记录</th>
						<th>艺术课考勤</th>
						<th class="long-td">艺术课堂学习参与度</th>
						<th class="long-td">艺术课程学习任务完成度</th>
						<th>艺术测评</th>
						<th>艺术技能测试</th>
						<th>总计</th>
					</tr>
					<tr>
						<td>数据条数</td>
						<td>{{ type200Num }}</td>
						<td>{{ type3341Num }}</td>
						<td>{{ type3342Num }}</td>
						<td>{{ type3343Num }}</td>
						<td>{{ type3344Num }}</td>
						<td>{{ type3351Num }}</td>
						<td>{{ totalNum }}</td>
					</tr>
					<tr>
						<td>综素成绩（分）</td>
						<td>{{ type200Score }}</td>
						<td>{{ type3341Score }}</td>
						<td>{{ type3342Score }}</td>
						<td>{{ type3343Score }}</td>
						<td>{{ type3344Score }}</td>
						<td>{{ type3351Score }}</td>
						<td>{{ totalScore }}</td>
					</tr>
				</table>
			</div>
			<div class="more">
				<h4>相关记录</h4>
				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>艺术课出勤率</h5>
					</el-col>
					<el-col :span="12" style="text-align: right"> </el-col>
				</el-row>
				<div class="table-box">
					<table border="1" cellspacing="0" rules="all">
						<tr>
							<th>评价项目</th>
							<th>学期</th>
							<th>评价方式</th>
							<th class="long-td">艺术课出勤率</th>
							<th class="long-td">综素得分</th>
						</tr>
						<tr v-if="artStudent">
							<td>艺术课出勤率</td>
							<td>{{ artStudent.termName }}</td>
							<td>客观记录</td>
							<td>{{ artStudent.artRatio }}</td>
							<td>{{ artStudent.indexUserScore }}</td>
						</tr>
						<tr v-else>
							<td class="empty-cell" colspan="5">暂无数据</td>
						</tr>
					</table>
				</div>
				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>艺术课堂学习参与度</h5>
					</el-col>
					<el-col :span="12" style="text-align: right"> </el-col>
				</el-row>
				<div class="table-box">
					<table border="1" cellspacing="0" rules="all">
						<tr>
							<th>评价项目</th>
							<th>学期</th>
							<th>评价方式</th>
							<th class="long-td">艺术课程学习参与度</th>
							<th class="long-td">综素得分</th>
						</tr>
						<tr v-if="learnProgressType1">
							<td>艺术课程学习参与度</td>
							<td>{{ learnProgressType1.termName }}</td>
							<td>客观记录</td>
							<td>{{ learnProgressType1.avgLearnProgress }}</td>
							<td>{{ type3342Score }}</td>
						</tr>
						<tr v-else>
							<td class="empty-cell" colspan="5">暂无数据</td>
						</tr>
					</table>
				</div>
				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>艺术课堂学习任务完成度</h5>
					</el-col>
					<el-col :span="12" style="text-align: right"> </el-col>
				</el-row>
				<div class="table-box">
					<table border="1" cellspacing="0" rules="all">
						<tr>
							<th>评价项目</th>
							<th>学期</th>
							<th>评价方式</th>
							<th class="long-td">艺术课程学习参与度</th>
							<th class="long-td">综素得分</th>
						</tr>
						<tr v-if="learnProgressType2">
							<td>艺术课程学习参与度</td>
							<td>{{ learnProgressType2.termName }}</td>
							<td>客观记录</td>
							<td>{{ learnProgressType2.avgLearnProgress }}</td>
							<td>{{ type3343Score }}</td>
						</tr>
						<tr v-else>
							<td class="empty-cell" colspan="5">暂无数据</td>
						</tr>
					</table>
				</div>
				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>艺术知识测评</h5>
					</el-col>
					<el-col :span="12" style="text-align: right"> </el-col>
				</el-row>
				<el-table :data="studentArtEva" style="width: 100%" border :header-cell-style="tableHeaderColor"
					:cell-style="cellStyleFun">
					<el-table-column type="index" label="序号" width="50">
					</el-table-column>
					<el-table-column prop="name" label="测评名称"> </el-table-column>
					<el-table-column prop="evaluationTypeName" label="测评类别">
						<template slot-scope="scope">艺术知识测评</template>
					</el-table-column>
					<el-table-column prop="term" label="学期" width="180">
					</el-table-column>
					<el-table-column prop="status" label="状态" width="120">
						<template slot-scope="scope">
							{{ scope.row.indexScore == "暂无" ? "未测评" : "已测评" }}
						</template>
					</el-table-column>
					<el-table-column prop="indexScore" label="综素得分" width="120">
					</el-table-column>
					<el-table-column label="操作" width="120">
						<template slot-scope="scope">
							<el-button @click="handleEvaluate(scope.row)" type="text" size="small" v-if="scope.row.indexScore == '暂无' && roleKey == 'student'">去测评</el-button>
							<el-button @click="handleEvaluateDetail(scope.row)" type="text" size="small" v-else>查看详情</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>学生艺术技能成绩</h5>
					</el-col>
					<el-col :span="12" style="text-align: right"> </el-col>
				</el-row>
				<div class="table-box">
					<table border="1" cellspacing="0" rules="all">
						<tr>
							<th class="long-td">学期</th>
							<th>项目1</th>
							<th>项目1成绩</th>
							<th>项目2</th>
							<th>项目2成绩</th>
							<th>平均成绩</th>
							<th>综素得分</th>
						</tr>
						<tr v-if="artSkillTest">
							<td>{{ artSkillTest.termName }}</td>
							<td>{{ artSkillTest.nameOne }}</td>
							<td>{{ artSkillTest.nameOneScore }}</td>
							<td>{{ artSkillTest.nameTwo }}</td>
							<td>{{ artSkillTest.nameTwoScore }}</td>
							<td>{{ artSkillTest.avgScore }}</td>
							<td>{{ artSkillTest.indexScore }}</td>
						</tr>
						<tr v-else>
							<td class="empty-cell" colspan="7">暂无数据</td>
						</tr>
					</table>
				</div>
				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>艺术展演/比赛</h5>
					</el-col>
					<el-col :span="12" style="text-align: right" v-if="curRole == 'student'">
						<el-button @click="uploadMyArt" type="primary" plain><i class="el-icon-circle-plus"></i>上传艺术比赛</el-button>
					</el-col>
				</el-row>
				<el-table :data="matchByArt" style="width: 100%" border :header-cell-style="tableHeaderColor"
					:cell-style="cellStyleFun">
					<el-table-column type="index" label="序号" width="50">
					</el-table-column>
					<el-table-column prop="matchName" label="展演/赛事名称"></el-table-column>
					<el-table-column prop="indexRuleLevel" label="展演/赛事级别">
					</el-table-column>
					<el-table-column prop="matchTime" label="展演/比赛时间">
						<template slot-scope="scope">
							{{ scope.row.matchTime.split(' ')[0] }}
						</template>
					</el-table-column>
					<el-table-column label="操作" width="120">
						<template slot-scope="scope">
							<el-button @click="handleDetailRace(scope.row)" type="text" size="small">查看</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>

		<!-- 查看详情 -->
		<el-dialog :title="title" :visible.sync="open" width="600px" center>
			<el-form ref="form" :model="form" style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding-top: 5px;
        ">
				<el-form-item>
					<span class="sname">{{ form.userName }}</span>
					<span class="snumb">学籍号：{{ form.studentCode }}</span>
				</el-form-item>
				<el-form-item label="展演/比赛名称：">
					<span class="scontent">{{ form.matchName }}</span>
				</el-form-item>
				<el-form-item label="展演/比赛级别：">
					<span class="scontent">{{ form._indexRuleLevel }}</span>
				</el-form-item>
				<el-form-item label="获奖等级：">
					<span class="scontent">{{ form.resultLevel }}</span>
				</el-form-item>
				<el-form-item label="展演/比赛时间：">
					<span class="scontent">{{ form.matchTime }}</span>
				</el-form-item>
				<el-form-item label="举办单位：">
					<span class="scontent">{{ form.awardedUnit }}</span>
				</el-form-item>
				<el-form-item label="所在学期：">
					<span class="scontent">{{ form.termName }}</span>
				</el-form-item>
				<!-- <el-form-item label="所在学校：">
          <span class="scontent">信德中学</span>
        </el-form-item> -->
				<el-form-item label="佐证材料：" v-if="form._showImgList">
					<el-image v-for="(item, index) in form._imgFileList" style="width: 100px; height: 100px;"
						:preview-src-list="form._imgFileList" :key="index" :src="item"></el-image>
				</el-form-item>
				<el-form-item label="佐证材料：" v-if="form._showOtherFileList">
					<a v-for="(item, idx) in form._otherFileList" :key="item" target="_blank" :download="'附件' + idx"
						:href="item">附件{{ idx + 1 }} </a>
				</el-form-item>
				<el-form-item label="备注说明：">
					<span class="scontent">{{ form.bz }}</span>
				</el-form-item>
				<el-form-item label="审核状态：">
					<span class="scontent">{{ aStatus(form.auditStatus) }}</span>
				</el-form-item>
				<el-form-item label="不通过原因：" v-if="form.auditStatus == 2">
					<span class="scontent">{{ form.causeDes }}</span>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="open = false">关 闭</el-button>
				<el-button type="primary" @click="reupload"
					v-if="form.auditStatus == 0 || form.auditStatus == 2">重新编辑上传</el-button>
			</span>
		</el-dialog>
		<!-- 个人艺术比赛 -->
		<el-dialog title="上传艺术展演/比赛" :visible.sync="dialog.addMyArt" width="600px" center>
			<el-form :model="addMyArtForm" :rules="addMyArtFormRules" label-width="130px" ref="addMyArtForm">
				<el-form-item label="学生姓名：" prop="userName">
					<span>{{ addMyArtForm.userName }}</span>
				</el-form-item>
				<el-form-item label="学籍号：" prop="studentCode">
					<span>{{ addMyArtForm.studentCode }}</span>
				</el-form-item>
				<el-form-item label="实践类别：" prop="matchType">
					<el-select style="width: 100%" v-model="addMyArtForm.matchType" placeholder="请选择实践类别">
						<el-option label="艺术比赛" :value="3"></el-option>
						<el-option label="艺术展演" :value="4"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="展演/比赛名称：" prop="matchName">
					<el-input style="width: 100%" placeholder="请输入展演/比赛名称" v-model="addMyArtForm.matchName"></el-input>
				</el-form-item>
				<el-form-item label="展演/比赛级别：" prop="indexRuleLevel">
					<el-select style="width: 100%;" v-model="addMyArtForm.indexRuleLevel" placeholder="请选择展演/比赛级别">
						<el-option v-for="(item, index) in constData.levelList" :key="index" :label="item.name"
							:value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="获奖等级：" prop="resultLevel">
					<el-input style="width: 100%;" placeholder="请输入获奖等级" v-model="addMyArtForm.resultLevel"></el-input>
				</el-form-item>
				<el-form-item label="展演/比赛时间：" prop="matchTime">
					<el-date-picker v-model="addMyArtForm.matchTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
						placeholder="请选择展演/比赛时间" style="width: 100%;">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="举办单位：" prop="awardedUnit">
					<el-input style="width: 100%;" placeholder="请输入举办单位" v-model="addMyArtForm.awardedUnit"></el-input>
				</el-form-item>
				<el-form-item label="所属学期：">
					<span>{{ addMyArtForm.termName }}</span>
				</el-form-item>
				<el-form-item label="佐证材料：" prop="file">
					<el-upload :action="upload.action" :accept="upload.accept" :limit="upload.limit" :multiple="upload.multiple"
						:file-list="upload.list" :on-change="uploadChange" :on-remove="uploadRemove" :auto-upload="upload.auto">
						<el-button size="small" type="primary" v-if="upload.list.length < 3">添加附件</el-button>
					</el-upload>
				</el-form-item>
				<el-form-item label="备注说明：" prop="bz">
					<el-input style="width: 100%;" type="textarea" v-model="addMyArtForm.bz" placeholder="情况说明,选填"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="closeDialog('addMyArt')">取 消</el-button>
				<el-button type="primary" @click="confirmUploadMyArt">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Search from "@/components/Search";
	import {
		getArtRaceDetail,
		addArtRace,
		artInfoDetail
	} from "@/api/teacher/quality/artistic";
	import {
		getDic
	} from "@/api/public/search"
	export default {
		data() {
			return {
				imgSrc: this.imageSrc(),
				curRole: this.$store.getters.roles[0].roleKey,
				termName: "",
				userId: "",
				title: "",
				open: false,
				form: {},
				termlist: [],
				roleKey: "",
				addMyArtForm: {
					userName: this.$store.getters.name,
					studentCode: this.$store.getters.loginName,
					termName: '',
					matchType: '',
					matchName: '',
					schoolName: '',
					matchTime: '',
					indexRuleLevel: '',
					resultLevel: '',
					awardedUnit: '',
					id: '',
					bz: ''
				},
				upload: {
					action: '',
					accept: '.jpg,.png,.doc,.docx,.xls,.xlsx,.pdf,.txt',
					limit: 3,
					multiple: false,
					list: [],
					auto: false
				},
				constData: {
					levelList: [],
					artLvlList: [],
					matchTpList: [],
					actTpList: []
				},
				dialog: {
					addMyArt: false
				},
				addMyArtFormRules: {
					matchType: [{
						required: true,
						message: "请选择实践类别",
						trigger: "change"
					}],
					matchName: [{
						required: true,
						message: "请输入展演/比赛名称",
						trigger: "blur"
					}, ],
					indexRuleLevel: [{
						required: true,
						message: "请选择展演/比赛级别",
						trigger: "change"
					}, ],
					matchTime: [{
						required: true,
						message: "请选择展演/比赛时间",
						trigger: "blur"
					}],
					awardedUnit: [{
						required: true,
						message: "请输入举办单位",
						trigger: "blur"
					}, ]
				},
				type200Num: 0,
				type200Score: 0,
				type3341Num: 0,
				type3341Score: 0,
				type3342Num: 0,
				type3342Score: 0,
				type3343Num: 0,
				type3343Score: 0,
				type3344Num: 0,
				type3344Score: 0,
				type3351Num: 0,
				type3351Score: 0,
				totalNum: 0,
				totalScore: 0,
				artStudent: {},
				learnProgressType1: {},
				learnProgressType2: {},
				studentArtEva: [],
				artSkillTest: {},
				matchByArt: []
			}
		},
		watch: {
			'addMyArtForm.matchType'(nv, ov) {
				if (nv != ov) {
					this.addMyArtForm.indexRuleLevel = ''
					if (nv == 3) {
						this.constData.levelList = this.constData.artLvlList
					} else if(nv == 4) {
						this.constData.levelList = this.constData.matchTpList
					}
				}
			}
		},
		components: {
			Search
		},
		created() {
			if (this.$store.getters.roles.length > 0) {
				this.roleKey = this.$store.getters.roles[0].roleKey;
			}
			this.userId = this.$store.getters.userId;
			this.$store.dispatch("GetTermList").then((res) => {
				this.termlist = res.data;
				this.termName = this.$store.getters.queryForm.termName;
				this.addMyArtForm.termName = this.$store.getters.queryForm.termName;
				this.$refs.search.init(this.termName);
				this.getAll();
				this.getMatchTpList()
				this.getArtLvlDic()
			});
		},
		methods: {
			// 获取所有信息
			getAll() {
				artInfoDetail({ 
					userId: this.userId,
					termName: this.termName
				}).then(res => {
					let d = res.data
					// 统统放到data里
					for(let i in d) {
						this[i] = d[i]
					}
				})
			},
			// 审核状态
			aStatus(st) {
				return ['未审核', '审核通过', '审核驳回'][st]
			},
			// 比赛级别字典
			getArtLvlDic() {
				getDic({
					type: "matchLevel"
				}).then(res => {
					this.constData.artLvlList = res.data
				})
			},
			// 获取比赛级别
			getMatchTpList() {
				getDic({ type: 'levelstatus' }).then((res) => {
					this.constData.matchTpList = res.data
				})
			},
			// 打开上传个人艺术比赛
			uploadMyArt() {
				this.addMyArtForm.matchType = ''
				this.addMyArtForm.matchName = ''
				this.addMyArtForm.matchTime = ''
				this.addMyArtForm.indexRuleLevel = ''
				this.addMyArtForm.awardedUnit = ''
				this.addMyArtForm.resultLevel = ''
				this.addMyArtForm.bz = ''
				this.addMyArtForm.id = ''
				this.upload.list = []
				this.openDialog('addMyArt')
			},
			// 打开弹窗
			openDialog(nm) {
				this.dialog[nm] = true
			},
			// 关闭弹窗
			closeDialog(nm) {
				this.dialog[nm] = false
			},
			// 确认上传个人艺术比赛
			confirmUploadMyArt() {
				this.$refs.addMyArtForm.validate(vld => {
					if (vld) {
						let fd = new FormData()
						fd.append('userName', this.addMyArtForm.userName)
						fd.append('matchType', this.addMyArtForm.matchType)
						fd.append('studentCode', this.addMyArtForm.studentCode)
						fd.append('matchName', this.addMyArtForm.matchName)
						fd.append('matchTime', this.addMyArtForm.matchTime)
						fd.append('indexRuleLevel', this.addMyArtForm.indexRuleLevel)
						fd.append('awardedUnit', this.addMyArtForm.awardedUnit)
						fd.append('resultLevel', this.addMyArtForm.resultLevel)
						if (this.upload.list.length > 0) {
							this.upload.list.forEach(v => {
								fd.append('file', v)
							})
						}
						if (this.addMyArtForm.id) {
							fd.append('id', this.addMyArtForm.id)
						}
						fd.append('bz', this.addMyArtForm.bz)
						addArtRace(fd).then(res => {
							this.$message.success(res.message)
							this.closeDialog('addMyArt')
							this.getAll()
						})
					} else {
						return false
					}
				})
			},
			// 材料上传
			uploadChange(file, fileList) {
				let rawTp = file.raw.type
				// pdf, excel表格, word文档, 图, 纯文本
				let allowTp = ['application/pdf', 'application/vnd.ms-excel',
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'image/gif',
					'image/png', 'image/jpeg', 'text/plain'
				]
				if (allowTp.indexOf(rawTp) == -1) {
					this.$message.error("材料仅支持pdf、excel表格、word、图片与纯文本格式！")
					fileList.pop()
				} else {
					this.upload.list.push(file.raw)
					this.$message.success("上传成功")
				}
			},
			// 材料删除
			uploadRemove(file, fileList) {
				this.upload.list = fileList
			},
			// 审核驳回重新编辑上传
			reupload() {
				this.open = false
				for (let i in this.form) {
					this.addMyArtForm[i] = this.form[i]
				}
				this.$nextTick(() => {
					// 找到级别
					let lvl = ''
					this.constData.levelList.forEach(v => {
						if (v.code == this.form.indexRuleLevel) {
							lvl = v.code
						}
					})
					this.addMyArtForm.indexRuleLevel = `${lvl}`
				})
				this.upload.list = []
				this.openDialog('addMyArt')
			},
			// 材料路径不是图片
			fileNotImg(url) {
				return [/\.xls$/, /\.xlsx$/, /\.doc$/, /\.docx$/, /\.pdf$/, /\.txt$/].some(v => v.test(url))
			},
			// 处理附件
			processAth(key) {
				let temp = key.split(",")
				let imgFileList = []
				let otherFileList = []
				// 材料按文件后缀名分流
				temp.forEach(v => {
					if (this.fileNotImg(v)) {
						otherFileList.push(this.imgSrc + v)
					} else {
						imgFileList.push(this.imgSrc + v)
					}
				})
				this.form._imgFileList = imgFileList
				this.form._otherFileList = otherFileList
				this.form._showImgList = imgFileList.length > 0
				this.form._showOtherFileList = otherFileList.length > 0
			},
			handleChange(val) {
				this.termName = val;
				this.getAll();
			},
			handleDetailRace(row) {
				getArtRaceDetail({
					matchId: row.id
				}).then((res) => {
					this.title = "艺术展演/比赛";
					this.addMyArtForm.id = row.id
					
					this.form = res.data
					this.form.matchType = row.matchType - 0
					if (this.form.matchType == 3) {
						this.form._indexRuleLevel = this.constData.artLvlList.filter(v => v.code == row.indexRuleLevel)[0].name
					} else if (this.form.matchType == 4) {
						this.form._indexRuleLevel = this.constData.matchTpList.filter(v => v.code == row.indexRuleLevel)[0].name
					}
					this.form.indexRuleLevel = row.indexRuleLevel
					
					if (this.form.proofFile) {
						this.processAth(this.form.proofFile)
					}
					this.open = true
				})
			},
			handleEvaluate(row) {
				this.$router.push({
					name: "test",
					params: row
				});
				sessionStorage["params"] = JSON.stringify(row);
			},
			handleEvaluateDetail(row) {
				row.userId = this.userId
				this.$router.push({
					name: "detail",
					params: row
				});
				sessionStorage["params"] = JSON.stringify(row);
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/studentResult.scss";

	.container {
		.user-nav {
			padding: 10px 12px;
		}
	}
</style>
<style scoped lang="scss">
	.table-box {
		table {
			th,
			td {
				width: 11% !important;
			}
	
			.long-td {
				width: 17% !important;
			}
		}
	}
	.user-nav .el-select>>>.el-input {
		margin: 10px 12px;
	}

	.el-form-item>>>.el-form-item__label {
		text-align: right;
		font-weight: bold;
		color: #303133;
	}

	.el-image {
		width: 100%;
	}
</style>