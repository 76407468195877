import {post, get} from '@/utils/axios'

// 成绩汇总
export const getResult = (params) => post('/shida-user-biz/TbMatch/getClassArtInfo',params)

export const getResultDetail = (params) => post('/shida-user-biz/TbMatch/getArtInfo',params)

export const getArtRaceById = (params) => post('/shida-user-biz/TbMatch/getUserArtMatchList',params)

export const getArtRaceDetail = (params) => post('/shida-user-biz/TbMatch/getArtMatchDetail',params)

export const getMatchLevel = (params) => post('/shida-user-biz/TbDic/getDicByType',params)

export const addArtRace = (params) => post('/shida-user-biz/TbMatch/saveOrUpdateArtMatch',params)

export const addRaceGroup = params => post('/shida-user-biz/TbMatch/saveOrUpdateBatchArtMatch', params)

export const removeArtRace = (params) => post('/shida-user-biz/TbMatch/deleteArtMatch',params)

export const getArtRaceList = (params) => post('/shida-user-biz/TbMatch/getClassArtMatchList',params)

export const getArtTest = (params) => post('/shida-user-biz/Health/getStudentArtEva',params)

// 艺术知识测评
export const getArtTestList = (params) => post('/shida-user-biz/Health/getTeacherManagerStudentEva',params)

export const getArtTestDetail = (params) => post('/shida-user-biz/TbEvaluation/getEvaResultDetailByUserId',params)

export const hurryUp = (params) => post('/shida-user-biz/TbMessagePerson/saveOrUpdate',params)

// 艺术比赛审核
export const artAudit = params => post('/shida-user-biz/TbMatch/updateAuditStatus', params)

// 艺术课考勤列表
export const artAttendList = params => post('/shida-user-biz/api/TbAttendanceArtClass/getData', params)

// 新增/修改艺术课考勤
export const updArtAttend = params => post('/shida-user-biz/api/TbAttendanceArtClass/saveOrUpdate', params)

// 艺术课出勤率
export const updArtAttendRt = params => post('/shida-user-biz/api/TbAttendanceArtStudent/getList', params)

// 艺术学习参与度/完成度list
export const artLearningProgressList = params => post('/shida-user-biz/api/TbArtLearnProgress/getList', params)

// 导入艺术学习参与度/完成度模板数据
export const artLearningProgressImp = params => post('/shida-user-biz/api/TbArtLearnProgress/importFile', params)

// 下载艺术学习参与度/完成度模板
export const artLearningProgressTpl = params => post('/shida-user-biz/api/TbArtLearnProgress/downTemplate', params, 'blob')

// 艺术实践 - 艺术技能成绩
export const artSkillScoreList = params => post('/shida-user-biz/api/TbArtSkillTest/getData', params)

// 现场测评 - 列表
export const artLiveTestList = params => post('/shida-user-biz/api/TbArtNowEva/getList', params)

// 现场测评 - 导入
export const artLiveTestIpt = params => post('/shida-user-biz/api/TbArtNowEva/importFile', params)

// 现场测评 - 模板
export const artLiveTestTpl = params => post('/shida-user-biz/api/TbArtNowEva/downTemplate', params, 'blob')

// 看板成绩汇总 - 学生详情
export const artInfoDetail = params => post('/shida-user-biz/TbMatch/getArt', params)

// 获取艺术素质测评表pdf
export const stuArtEvaTablePDF = params => get(`/shida-user-biz/upload/getArtEvaTablePdf?classId=${ params.classId }&userId=${ params.userId }&gradeYear=${ params.gradeYear }&termName=${ params.termName }`, {}, 'blob')